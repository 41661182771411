import React, { useState } from 'react';
import { Gallery as ImageGallery } from 'react-grid-gallery';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
const Certificates = () => {
  const [index, setIndex] = useState(-1);

  // Define the images directly within the component
  const images = [
    { src: '../images/certifications/1.jpg', original: '../images/certifications/1.jpg', width: 820, height: 550 },
    { src: '../images/certifications/2.png', original: '../images/certifications/2.png', width: 820, height: 550 },
    { src: '../images/certifications/3.png', original: '../images/certifications/3.png', width: 820, height: 550 },
    { src: '../images/certifications/4.png', original: '../images/certifications/4.png', width: 820, height: 550 },
    { src: '../images/certifications/5.png', original: '../images/certifications/5.png', width: 820, height: 550 },
    { src: '../images/certifications/6.png', original: '../images/certifications/6.png', width: 820, height: 550 },
    { src: '../images/certifications/7.png', original: '../images/certifications/7.png', width: 820, height: 550 },
    { src: '../images/certifications/8.png', original: '../images/certifications/8.png', width: 820, height: 550 },
    { src: '../images/certifications/9.png', original: '../images/certifications/9.png', width: 820, height: 550 },
    { src: '../images/certifications/10.jpg', original: '../images/certifications/10.jpg', width: 820, height: 550 },
    { src: '../images/certifications/11.png', original: '../images/certifications/11.png', width: 820, height: 550 },
    { src: '../images/certifications/12.jpg', original: '../images/certifications/12.jpg', width: 820, height: 550 },
    { src: '../images/certifications/13.jpg', original: '../images/certifications/13.jpg', width: 820, height: 550 },
    { src: '../images/certifications/14.jpg', original: '../images/certifications/14.jpg', width: 820, height: 550 },
    { src: '../images/certifications/15.png', original: '../images/certifications/15.png', width: 820, height: 550 },
    { src: '../images/certifications/16.jpg', original: '../images/certifications/16.jpg', width: 820, height: 550 },
    { src: '../images/certifications/17.jpg', original: '../images/certifications/17.jpg', width: 820, height: 550 },
    { src: '../images/certifications/18.jpg', original: '../images/certifications/18.jpg', width: 820, height: 550 },
    { src: '../images/certifications/19.png', original: '../images/certifications/19.png', width: 820, height: 550 },
    { src: '../images/certifications/20.png', original: '../images/certifications/20.png', width: 820, height: 550 },
    { src: '../images/certifications/21.png', original: '../images/certifications/21.png', width: 820, height: 550 },
    { src: '../images/certifications/22.png', original: '../images/certifications/22.png', width: 820, height: 550 },
    { src: '../images/certifications/23.jpg', original: '../images/certifications/23.jpg', width: 820, height: 550 },
    { src: '../images/certifications/24.jpg', original: '../images/certifications/24.jpg', width: 820, height: 550 },
    { src: '../images/certifications/25.jpg', original: '../images/certifications/25.jpg', width: 820, height: 550 },
    { src: '../images/certifications/26.jpg', original: '../images/certifications/26.jpg', width: 820, height: 550 },
    { src: '../images/certifications/27.png', original: '../images/certifications/27.png', width: 820, height: 550 },
    { src: '../images/certifications/28.jpg', original: '../images/certifications/28.jpg', width: 820, height: 550 },
    { src: '../images/certifications/29.jpg', original: '../images/certifications/29.jpg', width: 820, height: 550 },
    { src: '../images/certifications/30.png', original: '../images/certifications/30.png', width: 820, height: 550 },
    { src: '../images/certifications/31.png', original: '../images/certifications/31.png', width: 820, height: 550 },
  ];

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index) => {
    const clickedImage = images[index];
    if (clickedImage.original.endsWith('.pdf')) {
      // Open PDFs in a new tab
      window.open(clickedImage.original, '_blank');
    } else {
      setIndex(index);
    }
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <section className="py-16 text-center bg-gray-700">
      <h2 className="text-6xl text-black font-semibold mt-12">Certifications, Licenses, & Accreditations</h2>

      {/* React Grid Gallery */}
      <div className="mt-8">
        <ImageGallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
      </div>

      {/* React Image Lightbox */}
      {!!currentImage && (currentImage.original.endsWith('.png') || currentImage.original.endsWith('.jpg')) && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={`Certification ${index + 1}`}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </section>
  );
};

export default Certificates;
